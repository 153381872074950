<template>
  <div>
    <div style="padding: 8px">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn class="ma-0"
               style="margin-top: -4px !important;"
               fab
               :dark="!disabled"
               color="primary"
               small
               v-on="on"
               v-if="existePagStatusDiverg"
               @click.stop="$refs.uploadArquivo.open()"
               :disabled="disabled">
          <v-icon>attach_file</v-icon>
        </v-btn>
        <v-btn class="ma-0"
               style="margin-top: -4px !important;"
               fab
               :dark="!disabled"
               color="primary"
               small
               v-on="on"
               v-else
               @click.stop="$refs.uploadArquivo.open()"
               :disabled="disabled">
          <v-icon>attach_file</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('title.upload_evidencia') }}</span>
    </v-tooltip>
    </div>
    <v-dialog v-model="showModal" max-width="800">
      <v-card>
        <v-card-title>
        <span class="bar">
          {{ $tc('title.atencao_status_diverg') }}
        </span>
        </v-card-title>
        <br>
        <v-card-subtitle>
          {{ $tc('title.confirm_upload_pag_div') }}
          {{
            exibePagStatusDiverg
          }}
          {{ $tc('title.confirm_upload_pag_div_aguard') }}
        </v-card-subtitle>
        <v-card-subtitle>
          {{ $tc('title.confirm_upload_pag') }}
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="acaoModal(false)">{{ $t('label.nao') }}</v-btn>
          <v-btn color="primary" v-if="exisPagStatusCorreto" @click.stop="acaoModal(false),$refs.uploadArquivo.open()">{{ $t('label.sim') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <upload-file-form
      ref="uploadArquivo"
      :title="$t('label.upload_evidencia')"
      :url="uriUpload"
      :auto-process-queue="false"
      @UPLOADFILE_SENDING="enviandoArquivo"
      @UPLOADFILE_FILE_ADDED="preencherDescricaoComNomeArquivo"
      @UploadArquivo__success="uploadSucesso">
      <v-text-field
        v-model="descricaoArquivo"
        :rules="[rules.required]"
        :counter="150"
        maxlength="150"
        :label="`${$t('label.descricao')}*`"
        required
      ></v-text-field>
    </upload-file-form>
  </div>
</template>

<script>
import basePath from '@/produto/common/functions/api-resource';
import UploadFileForm from '@/produto/shared-components/upload/UploadFileForm';

export default {
  name: 'PagamentoListaUpload',
  components: {
    UploadFileForm,
  },
  props: {
    pagamentos: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    uriUpload() {
      const urlUpload = basePath('job', 'suzano/pagamento/listagem');
      return `${urlUpload}/upload`;
    },
    existePagStatusDiverg() {
      return this.pagamentos.filter((el) => el.status === 'AGUARDANDO_APROVACAO').length > 0;
    },
    exibePagStatusDiverg() {
      return JSON.stringify(this.pagamentos.filter((el) => el.status === 'AGUARDANDO_APROVACAO').map((p) => p.id));
    },
    exisPagStatusCorreto() {
      return this.pagamentos.filter((el) => el.status === 'PENDENTE' || el.status === 'EM_ANALISE').length > 0;
    },
  },
  data() {
    return {
      showModal: false,
      descricaoArquivo: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    enviandoArquivo(data, xhr, formData) {
      formData.append('descricao', encodeURI(this.descricaoArquivo));
      const ids = this.pagamentos.map((p) => p.id);
      if (ids.length === 0) {
        this.$toast(this.$t('message.nenhum_pag_selecionado_pend_analise'));
        this.abrirSidebar = false;
        return;
      }
      formData.append('idsPagamentos', JSON.stringify(ids));
    },
    preencherDescricaoComNomeArquivo(file) {
      this.descricaoArquivo = file.name;
    },
    uploadSucesso() {
      this.$toast(this.$t('label.upload_arquivo_sucesso'));
    },
    acaoModal(boolean) {
      this.showModal = boolean;
    },
  },
};
</script>
